import { Box, Flex, Grid, GridItem, Image, Text, VStack } from "@chakra-ui/react";
import Street from "../assets/images/main_street.jpg";

import FAQ from "../components/faq";
import Team from "../components/team";
import Footer from "../components/footer";
import Strategy from "../components/strategy";
import Town from "../components/town";

export default function Home() {
  return (
    <Box pt={{ base: "60px", md: "60px" }} px={{ base: "2px", md: "24px", lg: "32px", xl: "40px" }}>
      <Grid
        templateColumns="repeat(5, 1fr)"
        gap={2}
        bgImage={"linear-gradient(rgba(255, 255, 255, 0.3),rgba(255, 255, 255, 0.3))"}
      >
        <GridItem colSpan={{ base: 5, xl: 3 }}>
          <Flex direction={"column"} px={2} h={"100%"} justifyContent={"space-evenly"}>
            <Box>
              <Text fontSize={{ base: "2xl", md: "4xl", xl: "5xl" }} fontWeight={700} align={"center"}>
                Welcome to Nugget Fund
              </Text>
              <Text fontSize={{ base: "xl", md: "2xl", xl: "3xl" }} fontWeight={400} align={"center"}>
              Your community for passive-aggressive investment and income.
              </Text>
            </Box>
            <Text mt={{ base: 4 }} fontSize={{ base: "sm", md: "md", xl: "lg" }}>
            Nugget Fund is designed to maximize investor returns by strategically integrating multiple investment strategies, including Mining, Yield Farming, Trading Bots, and alternative investments. Our approach prioritizes effective risk management, ensuring that capital is protected within reasonable limits while optimizing reward potential.
            </Text>
            <Text mt={{ base: 4 }} fontSize={{ base: "sm", md: "md", xl: "lg" }}>
            Investors can participate by depositing USDC or USDT, which allows them to mint (forge) and stake $NGET, unlocking the ability to earn weekly rewards. This seamless system removes the complexity of managing individual investments, many of which can involve significant risk and require continuous monitoring.
            </Text>
            <Text mt={{ base: 4 }} fontSize={{ base: "sm", md: "md", xl: "lg" }}>
            Rather than navigating multiple strategies independently, investors benefit from a diversified portfolio managed daily by our expert team. We carefully select and oversee top-performing opportunities, ensuring capital is allocated efficiently for sustainable growth. This hands-off approach allows investors to earn consistent returns without the stress of actively managing their positions, providing both peace of mind and financial opportunity.
            </Text>
            <Text mt={{ base: 4 }} fontSize={{ base: "sm", md: "md", xl: "lg" }}>
            You no longer need to master every investment strategy to achieve strong returns.


            </Text>
          </Flex>
        </GridItem>
        <GridItem colSpan={{ base: 5, xl: 2 }}>
          <VStack>
            <Image src={Street} />
          </VStack>
        </GridItem>
      </Grid>

      <Strategy />
      <Town />
      
      <Team />
      <FAQ />
      <Footer />
    </Box>
  );
}
