import { HStack, Text } from "@chakra-ui/react";
import LazyDog from "../assets/images/team/lazydog.png";
import Marcelo from "../assets/images/team/marcelo.png";
import PocketAces from "../assets/images/team/pocketaces.png";
import JayC from "../assets/images/team/jayc.webp";
import ShinyJackel from "../assets/images/team/shinyjackel.webp";

const team = [
  [
    { name: "Lazy Dog", image: LazyDog },
    { name: "Marcelo", image: Marcelo },
    { name: "JayC", image: JayC },
    
  ],
  [
    { name: "PocketAces", image: PocketAces },
    
    { name: "ShinyJackel", image: ShinyJackel },
  ],
];

export default function Team() {
  return (
    <>
      <div id="team">&nbsp;</div>
      <HStack mt={16} justifyContent={"center"}>
        <Text fontSize={{ base: "4xl", md: "5xl" }} fontWeight={600} pb={4}>
          Team
        </Text>
      </HStack>
      {team.map((group, index) => (
        <div key={index} className="flex flex-wrap justify-center">
          {group.map(({ image, name }) => (
            <img key={name} src={image} alt={name} className="max-w-96 shrink" />
          ))}
        </div>
      ))}
    </>
  );
}
